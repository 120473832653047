<template>
  <div class="step">
    <div class="step_content">
      <div class="alert alert-success" role="alert">
        <h1>Fertig !</h1>
        <p class="text-center">
          Es ist geschaft!<br/>
          Nochmal von vorne beginnen?</p>
      </div>
      <div class="mt-4">
        <h1>{{getTitle}}</h1>
        <div class="step_image">
          <img class="img-fluid" :src="getImage" :alt="getAlt" />
        </div>
        <p>{{getText}}</p>
      </div>
    </div>
    <OTIFooter>
      <router-link :to="{ name: 'Step', params: { id: getStartStep }}" class="btn btn-success">Nochmal <i class="bi bi-play-circle-fill"></i></router-link>
    </OTIFooter>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import OTIFooter from '@/components/ui/OTIFooter.vue'

export default {
  name: 'Again',
  components: {
    OTIFooter,
  },
  computed: {
    ...mapGetters([
      'getTitle',
      'getImage',
      'getAlt',
      'getText',
      'getStartStep'
    ]),
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
