<template>
  <div class="step_content">
    <h1>{{ step.title }}</h1>
    <p>{{ step.text }}</p>
    <div v-if="isAnimated" class="step_image">
      <img class="img-fluid" :src="animatedImage" :alt="step.alt" />
    </div>
  </div>
  <OTIFooter>
      <router-link :to="nextStep" class="btn btn-success">Weiter <i class="bi bi-arrow-right-circle-fill"></i></router-link>
  </OTIFooter>
</template>

<script>
import OTIFooter from '@/components/ui/OTIFooter.vue'

export default {
  name: 'StepAnimation',
  props: ['step'],
  components: {
    OTIFooter,
  },
  data: function () {
    return {
      animation: null,
      animationIndex: 0
    }
  },
  computed: {
    nextStep() {
      return this.step.next ? { name: 'Step', params: { id: this.step.next }} : {name: 'Again'}
    },
    animatedImage() {
      return this.step.animation[this.animationIndex];
    },
    isAnimated() {
      return Array.isArray(this.step.animation);
    }
  },
  methods: {
    initAnimation() {
      if (this.animation) {
        clearInterval(this.animation);
      }
      if (this.isAnimated) {
        this.animation = setInterval(() => {
          console.log(this.animationIndex);
          this.animationIndex++;
          if (this.animationIndex >= this.step.animation.length)
            this.animationIndex = 0;
        }, this.step.interval);
      }
    }
  },
  watch: {
    step() {
      this.initAnimation();
    }
  },
  created: function () {
      this.initAnimation();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
