import { createStore } from 'vuex'
import axios from 'axios'

const debug = process.env.NODE_ENV !== 'production'
const trusted = [
  'https://rein-zieh.github.io/',
  'https://mitas-app.de/'
]

export default createStore({
  strict: debug,
  state: {
    debug: debug,
    trusted: trusted,
    oti: null,
    steps: {},
    start: null,
    loading: false,
    error: "",
    webservice: "",
    prefs: {
      primary: "#c00000",
      primaryRGB: "",
      logo: ""
    }
  },
  getters: {
    isLoading: state => {
      // return Boolean(state.checklist)
      return state.loading;
    },
    getError: state => {
      return state.error
    },
    getOti: state => {
      return state.oti
    },
    getUrl: state => {
      return state.webservice
    },
    getTitle: state => {
      return state.oti.title ?? "Kein Titel vorhanden."
    },
    getImage: state => {
      return state.oti.image ?? "" 
    },
    getAlt: state => {
      return state.oti.alt ?? "Keine Abbildung vorhanden." 
    },
    getText: state => {
      return state.oti.text ?? "" 
    },
    getMeta: state => {
      return state.oti.meta ?? null
    },
    getLogo: state => {
      return state.prefs.logo;
    },
    getSteps: state => {
      return state.steps ?? null
    },
    getStep: (state) => (id) => {
      return state.steps[id] ?? null
    },
    getStartStep: state => {
      //return state.steps[state.startId] ?? null
      return state.start ?? null
    },
  },
  mutations: {
    setLoading: (state, loading) => {
      state.loading = loading;
    },
    setError: (state, error) => {
      state.error = error;
    },
    setOtiURL: (state, url) => {
      if (url) {
        var trust = state.trusted.some(value => url.startsWith(value))
        if (trust) {
          state.webservice = url;
        } else {
          state.error = "Keine erlaubte URL!";
        }
      }
      else
        state.error = "Kein oti-File gefunden!";
    },
    setOti: (state, oti) => {
      state.oti = oti;
    },
    setStep: (state, step) => {
      state.steps[step.id] = step;
    },
    setStart: (state, start) => {
      state.start = start;
    },
    setPrefs: (state, prefs) => {
      if (prefs) {
        // Primary color parsen und setzen
        let temp = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(prefs.color);
        state.prefs.primaryRGB = temp ? parseInt(temp[1], 16) + ',' + parseInt(temp[2], 16) + ',' + parseInt(temp[3], 16) : "";
        state.prefs.primary = state.prefs.primaryRGB ? prefs.color : "";

        // Primary color als CSS-Variablen für Bootstrap
        if (state.prefs.primaryRGB) {
          let root = document.documentElement;
          root.style.setProperty('--bs-primary', state.prefs.primary);
          root.style.setProperty('--bs-primary-rgb', state.prefs.primaryRGB);
        } 

        // Setzen des Logos
        state.prefs.logo = prefs.logo;
      }  
    },
    serialize (state, {steps, next} ) {
      if (!Array.isArray(steps)) {
        return;
      }

      for (var i = 0; i < steps.length; i++) {
        if (steps[i].id) {

          var item = {};
          item.id = steps[i].id;
          item.title = steps[i].title ?? 'Schritt hat kein Titel';
          item.type = steps[i].type ?? 'step';
          item.text = steps[i].text ?? '';
          item.image = steps[i].image ?? '';
          item.alt = steps[i].alt ?? '';
        
          switch (item.type) {
            case 'step' :
              item.next = steps[i+1] ? steps[i+1].id : next;
              this.commit('setStep', item);
              break;
            case 'step-timer' :
            case 'wait-timer' :
              item.next = steps[i+1] ? steps[i+1].id : next;
              item.countdown = steps[i].countdown ?? null;
              this.commit('setStep', item);
              break;
            case 'step-animation' :
              item.next = steps[i+1] ? steps[i+1].id : next;
              item.animation = steps[i].animation ?? null;
              item.interval = steps[i].interval ?? 1000;
              item.interval = Math.max(item.interval, 500);
              this.commit('setStep', item);
              break;
            case 'if-then-else' :
            case 'if-then' :
              item.thenText = steps[i].then_text ?? 'Ja';
              item.elseText = steps[i].else_text ?? 'Nein';

              if (steps[i].then && steps[i].then[0]) {
                item.then = steps[i].then[0].id ?? '';
              } else {
                item.then = steps[i+1] ? steps[i+1].id : next;
              }

              if (steps[i].else && steps[i].else[0]) {
                item.else = steps[i].else[0].id ?? '';
              } else {
                item.else = steps[i+1] ? steps[i+1].id : next;
              }

              this.commit('setStep', item);
              this.commit('serialize', {'steps' : steps[i].then, 'next' : steps[i+1] ? steps[i+1].id : next});
              this.commit('serialize', {'steps' : steps[i].else, 'next' : steps[i+1] ? steps[i+1].id : next});
              break;
            case 'checklist' :
              item.checkitems = steps[i].checkitems;
              item.next = steps[i+1] ? steps[i+1].id : next;
              this.commit('setStep', item);
              break;
            default:
              this.commit('setError', 'Step ' + i + ' has no valid type');
              return;
          }
        } else {
          this.commit('setError', 'Step ' + i + ' has no id');
          return;  
        }
      }
    }
  },
  actions: {
    FROM_SERVER: ({ state, commit }) => {
      commit('setLoading', true)
      axios
      .get(state.webservice)
        .then(response => {
          commit('setOti', response.data);
          if (!state.error) {
            commit('setPrefs', state.oti.preferences);
            commit('serialize', {'steps' : state.oti.steps, 'next' : ''});
            commit('setStart', state.oti.steps[0].id);
          }
          commit('setLoading', false);
        })
        .catch(error => {
          commit('setLoading', false);
          console.log(error);
        })
    },
  },
  modules: {
  }
})

