<template>
  <div class="step_content">
    <h1>{{ step.title }}</h1>
    <p>{{ step.text }}</p>
    <fieldset>
      <legend>{{ step.title }}</legend>
      <div v-for="(item, index) in step.checkitems" :key="index">
        <input type="checkbox" :id="'box-'+index" :value="'box-'+index" v-model="checkedItems"> 
        <label :for="'box-'+index">{{item.text}}</label>
      </div>
      {{checkedItems}}, {{allChecked}}

    </fieldset>
    <!-- div if="step.image" class="step_image">
      <img class="img-fluid" :src="step.image" :alt="step.alt" />
    </div -->
  </div>
  <OTIFooter>
      <router-link v-if="allChecked" :to="nextStep" class="btn btn-success">Weiter <i class="bi bi-arrow-right-circle-fill"></i></router-link>
      <router-link v-else :to="nextStep" class="btn btn-disabled">Weiter <i class="bi bi-arrow-right-circle-fill"></i></router-link>
  </OTIFooter>
</template>

<script>
import OTIFooter from '@/components/ui/OTIFooter.vue'

export default {
  name: 'Step',
  props: ['step'],
  components: {
    OTIFooter,
  },
  data() {
    return {
      checkedItems: []
    }
  },
  computed: {
    nextStep() {
      return this.step.next ? { name: 'Step', params: { id: this.step.next }} : {name: 'Again'}
    },
    allChecked() {
      return this.checkedItems.length >= this.step.checkitems.length
    }
  },
}
</script>