<template>
    <nav class="oti-footer navbar fixed-bottom navbar-light bg-light">
        <div class="container-fluid justify-content-center">
            <slot></slot>
        </div>
    </nav>
  </template>

<script>
export default {
  name: 'OTIFooter',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
