<template>
  <div class="step_content">
    <h1>{{ step.title }}</h1>
    <p>{{ step.text }}</p>
    <div if="step.image" class="step_image">
      <img class="img-fluid" :src="step.image" :alt="step.alt" />
    </div>
  </div>
  <OTIFooter>
      <router-link :to="nextStep" class="btn btn-success">Weiter <i class="bi bi-arrow-right-circle-fill"></i></router-link>
  </OTIFooter>
</template>

<script>
import OTIFooter from '@/components/ui/OTIFooter.vue'

export default {
  name: 'Step',
  props: ['step'],
  components: {
    OTIFooter,
  },
  computed: {
    nextStep() {
      return this.step.next ? { name: 'Step', params: { id: this.step.next }} : {name: 'Again'}
    },
  },
}
</script>