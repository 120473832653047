<template>
  <div class="step_content">
    <h1>{{ step.title }}</h1>
    <p>{{ step.text }}</p>
    <div if="step.image" class="step_image">
      <img class="img-fluid" :src="step.image" :alt="step.alt" />
    </div>
  </div>
  <OTIFooter>
    <button v-if="isCountdown" @click="startCountdown" class="btn btn-warning">Start Countdown <i class="bi bi-stopwatch-fill"></i></button>
    <button v-else-if="isCountdownRunning" class="btn btn-disabled" disabled>noch {{h}}{{m}}{{s}}</button>
    <router-link v-else :to="nextStep" class="btn btn-success">Weiter <i class="bi bi-arrow-right-circle-fill"></i></router-link>
  </OTIFooter>
</template>

<script>
import OTIFooter from '@/components/ui/OTIFooter.vue'

export default {
  name: 'StepTimer',
  props: ['step'],
  components: {
    OTIFooter,
  },
  data: function () {
    return {
      timer: null,
      countdown: 0,
      countdownStatus: 0, // [2 = "ready", 1 = "running", 0 = "finished"]
    }
  },
  computed: {
    nextStep() {
      return this.step.next ? { name: 'Step', params: { id: this.step.next }} : {name: 'Again'}
    },
    h() {
      let value = Math.floor(this.countdown / 3600);
      let unit = value == 1 ? ' Stunde, ' : ' Stunden, ';
      return this.countdown >= 3600 ? ("0" + value).substr(-2) + unit : '';
    },
    m() {
      let value =  Math.floor(this.countdown % 3600 / 60);
      let unit = value == 1 ? ' Minute, ' : ' Minuten, ';
      return this.countdown >= 60 ? ("0" + value).substr(-2) + unit : '';
    }, 
    s() {
      let value =  this.countdown % 60;
      let unit = value == 1 ? ' Sekunde' : ' Sekunden';
      return ("0" + value).substr(-2) + unit;
    },
    isCountdown() {
      return this.countdownStatus == 2;
    },
    isCountdownRunning() {
      return this.countdownStatus == 1;
    },
    isCountdownFinished() {
      return this.countdownStatus == 0;
    }
  },
  methods: {
    startCountdown() {
      if (this.countdownStatus == 2) {
        this.countdownStatus = 1;
        this.timer = setInterval(() => {
          this.countdown -= 1;
          if (this.countdown <= 0) {
            this.countdownStatus = 0;
            clearInterval(this.timer);
          }
        }, 1000);
      }
    },
    initCountdown() {
      if (this.timer) {
        clearInterval(this.timer);
      }
      if (this.step.countdown) {
        var h = this.step.countdown.match(/(\d+)\s*h/);
        var m = this.step.countdown.match(/(\d+)\s*m/);
        var s = this.step.countdown.match(/(\d+)\s*s/);
        this.countdown = h ? parseInt(h[1]) * 3600 : 0;
        this.countdown += m ? parseInt(m[1]) * 60 : 0;
        this.countdown += s ? parseInt(s[1]) : 0;
        this.countdownStatus = 2;
      } else {
        this.countdownStatus = 0;
      }
    }
  },
  watch: {
    step() {
      console.log(this.step.id);
      this.initCountdown();
    }
  },
  created: function () {
      this.initCountdown();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
