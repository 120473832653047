<template>
  <div class="step_content text-start">
    <h1>Open Task Instruction Player (otiPlayer)</h1>
    <p>Diese Web-Applikation spielt oti-Files aus dem Web ab. 
       Oti-Files beinhalten multimodale Anleitungen mit Text, 
       Bildern, Videos und/oder Ton.</p>
    <p>Die Anleitungen selbst sind nicht Teil dieser Applikation und 
       unterliegen nicht dem Einfluss der otiPlayer-Entwickler. 
       Verantwortlich für den Inhalt der Anleitungen sind die Bereitsteller
       der Anleitungen. Informationen über den Bereitsteller einer 
       Anleitung finden Sie auf der Startseite jeder Anleitung.</p>
  </div>
</template>
