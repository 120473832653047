<template>
  <nav class="navbar navbar-expand-sm navbar-dark bg-primary">
    <div class="container-fluid">
      <a v-if="getLogo" class="navbar-brand navbar-brand-logo" href="#">
        <img class="navbar-brand_logo" :src="getLogo" alt="Logo" height="43" />
      </a>
      <a v-else class="navbar-brand navbar-brand-text" href="#">
        <span class="oti">oti</span>player
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto mb-lg-0 w-20">
          <li class="nav-item">
            <router-link class="nav-link active" aria-current="page" to="/" exact>Anleitung</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/about">Über <span class="oti">oti</span></router-link>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Einstellungen
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <!-- li>
                <form class="form-floating">
                  <input class="form-control" id="primarycolor" v-model="primary" />
                  <label for="primarycolor">Farb-Schema (Test): </label>
                </form>
              </li>
              <li><hr class="dropdown-divider"></li -->
              <li><a class="dropdown-item" href="#">Something else here</a></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <main class="mt-4">
    <p v-if="getError">Fehler: {{getError}}</p>
    <p v-if="isLoading">Lade...</p>
    <router-view v-else />
  </main>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'oti-player',
  created: function () {
    const urlParams = new URLSearchParams(window.location.search);
    const url = urlParams.get('oti');
    this.$store.commit('setOtiURL', url);
    this.$store.dispatch('FROM_SERVER');
  },
  data() {
    return {
      primary: '#c00000'
    }
  },
  computed: {
    ...mapGetters([
      'isLoading',
      'getError',
      'getOti',
      'getTitle',
      'getImage',
      'getAlt',
      'getText',
      'getLogo',
      'getSteps',
    ]),
    debug () {
      return this.$store.state.debug
    }
  }
}
</script>
