<template>
  <div class="oti-meta text-start">
    <div v-if="getMeta && getMeta.author">
      <b>Autor:</b>
      <div v-if="getMeta.author.name" class="ms-4">
        Name: {{getMeta.author.name}}<br>
      </div>
      <div v-if="getMeta.author.organisation" class="ms-4">
        Organisation: {{getMeta.author.organisation}}
      </div>
      <div v-if="getMeta.author.email" class="ms-4">
        E-Mail: {{getMeta.author.email}}
      </div>
    </div>
    <div>
      <b>Quelle:</b>
      <div class="ms-4">{{getUrl}}</div>
      <b>Lizenz:</b>
      <div class="ms-4">{{getMeta.license}}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'OTIMeta',
  props: ['meta'],
  computed: {
    ...mapGetters([
      'getMeta',
      'getUrl',
    ]),
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
