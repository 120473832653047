<template>
  <div class="step_content">
    <h1>{{ step.title }}</h1>
    <p>{{ step.text }}</p>
    <div v-if="step.image" class="step_image">
      <img class="img-fluid" :src="step.image" :alt="step.alt" />
    </div>
  </div>
  <OTIFooter>
    <router-link :to="elseStep" class="btn btn-danger"><i class="bi bi-x-circle-fill left"></i> {{step.elseText}}</router-link>
    <router-link :to="thenStep" class="btn btn-success">{{step.thenText}} <i class="bi bi-check-circle-fill right"></i></router-link>
  </OTIFooter>
</template>

<script>
import OTIFooter from '@/components/ui/OTIFooter.vue'

export default {
  name: 'IfThenElse',
  props: ['step'],
  components: {
    OTIFooter,
  },
  computed: {
    thenStep () {
      return this.step.then ? { name: 'Step', params: { id: this.step.then }} : {name: 'Again'}
    },
    elseStep () {
      return this.step.else ? { name: 'Step', params: { id: this.step.else }} : {name: 'Again'}
    }
  }}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
