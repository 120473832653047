<template>
  <div v-if="!isLoading && !getError" class="step">
    <div class="step_content">
      <h1>{{getTitle}}</h1>
      <div class="step_image">
        <img class="img-fluid" :src="getImage" :alt="getAlt" />
      </div>
      <p>{{getText}}</p>
      <OTIMeta></OTIMeta>
    </div>
    <OTIFooter>
      <router-link :to="{ name: 'Step', params: { id: getStartStep }}" class="btn btn-success">Start <i class="bi bi-play-circle-fill"></i></router-link>
    </OTIFooter>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import OTIFooter from '@/components/ui/OTIFooter.vue'
import OTIMeta from '@/components/ui/OTIMeta.vue'

export default {
  name: 'Home',
  components: {
    OTIFooter,
    OTIMeta,
  },
  computed: {
    ...mapGetters([
      'isLoading',
      'getError',
      'getTitle',
      'getImage',
      'getAlt',
      'getText',
      'getStartStep'
    ]),
  }
}
</script>
