<template>
  <div class="step">
    <Step v-if="isStep" :step="thisStep" />
    <StepAnimation v-else-if="isStepAnimation" :step="thisStep" />
    <StepTimer v-else-if="isStepTimer" :step="thisStep" />
    <IfThenElse v-else-if="isIfThenElse" :step="thisStep" />
    <CheckList v-else-if="isCheckList" :step="thisStep" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Step from '@/components/steps/Step.vue'
import StepTimer from '@/components/steps/StepTimer.vue'
import StepAnimation from '@/components/steps/StepAnimation.vue'
import IfThenElse from '@/components/steps/IfThenElse.vue'
import CheckList from '@/components/steps/CheckList.vue'

export default {
  name: 'Home',
  components: {
    Step,
    StepTimer,
    StepAnimation,
    IfThenElse,
    CheckList
  },
  computed: {
    ...mapGetters([
      'getStep',
    ]),
    thisStep() {
      return this.getStep(this.$route.params.id)
    },
    isStep() {
      return this.getStep(this.$route.params.id).type == 'step'
    },
    isStepAnimation() {
      return this.getStep(this.$route.params.id).type == 'step-animation'
    },
    isStepTimer() {
      return this.getStep(this.$route.params.id).type == 'step-timer'
          || this.getStep(this.$route.params.id).type == 'wait-timer'
    },
    isIfThenElse() {
      return this.getStep(this.$route.params.id).type == 'if-then-else'
          || this.getStep(this.$route.params.id).type == 'if-then'
    },
    isCheckList() {
      return this.getStep(this.$route.params.id).type == 'checklist'
    }
  }
}
</script>
